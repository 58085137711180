import { Injectable } from '@angular/core';
import moment from 'moment';

import { getMomentFromTimestamp } from '../_shared/helpers/helper-functions';
import { SetCookieType } from './types/set-cookie.type';

@Injectable()
export class CookieService {
  sharedDomain = false;

  /**
   * This is legacy dashboard refreshToken cookie which causes users logged out
   * https://cloudtalk.atlassian.net/browse/APPS-1073
   */
  removeDeprecatedRefreshTokenCookie(): void {
    document.cookie =
      'refreshToken=; ' +
      'domain=.cloudtalk.io; ' +
      'path=/ct-auth/api/auth; ' +
      'expires=Thu, 01 Jan 1970 00:00:00 UTC; ' +
      'secure; ' +
      'SameSite=None';
  }

  getCookie(name: string): string {
    const ca: string[] = document.cookie.split(';');
    const caLen: number = ca.length;
    const cookieName = `${name}=`;
    let c: string;

    for (let i = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) === 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  deleteCookie(name: string, domain: string, path: string): void {
    let dStr = '';
    if (domain) {
      dStr = ' domain=' + domain + ';';
    } else if (this.sharedDomain) {
      dStr = ' domain=.cloudtalk.io;';
    }

    let pStr = '';
    if (path) {
      pStr = ' path=' + path + ';';
    }
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; ${dStr} ${pStr} SameSite=None; Secure; Partitioned;`;
    // support for old cookies
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; ${dStr} ${pStr} SameSite=None; Secure;`;
  }

  getAllCookieNames(): string[] {
    return document.cookie
      .split(';')
      .map((cookie: string) => cookie.split('=')[0]);
  }

  setCookie(setCookieType: SetCookieType): void {
    if (this.sharedDomain) {
      setCookieType.domain = '.cloudtalk.io';
    }
    if (!setCookieType.expireDays && !setCookieType.expireTimestamp) {
      return;
    }

    let expireDate: moment.Moment = null;
    if (setCookieType.expireTimestamp) {
      expireDate = getMomentFromTimestamp(setCookieType.expireTimestamp);
    }
    if (setCookieType.expireDays) {
      if (expireDate) {
        expireDate.add(setCookieType.expireDays, 'days');
      } else {
        expireDate = moment().add(setCookieType.expireDays, 'days');
      }
    }
    if (setCookieType.expireHours && expireDate) {
      expireDate.add(setCookieType.expireHours, 'hours');
    }
    const expires = `expires=${expireDate?.toDate().toUTCString()}`;
    setCookieType.path = setCookieType.path
      ? `; path=${setCookieType.path}`
      : '';
    setCookieType.domain = setCookieType?.domain
      ? `; domain=${setCookieType.domain}`
      : '';
    const partitioned = setCookieType?.partitioned ? 'Partitioned;' : '';

    // SameSite parameter must be to set to None in order to allow cookies storing for our CTIs (eg. Zendesk/Salesforce)
    document.cookie = `${setCookieType.name}=${setCookieType.value}; ${expires}${setCookieType.path}${setCookieType.domain}; SameSite=None; Secure; ${partitioned}`;
  }
}
