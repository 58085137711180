import {
  CdkVirtualScrollViewport,
  VirtualScrollStrategy,
} from '@angular/cdk/scrolling';
import { last } from 'lodash-es';
import { noop, Observable, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

interface Range {
  start: number;
  end: number;
}

const BUFFER = 100;

export class FixedRangesVirtualScrollStrategy implements VirtualScrollStrategy {
  constructor(private itemsRanges: Range[] = []) {}

  private viewport?: CdkVirtualScrollViewport;
  private scrolledIndexChange$ = new Subject<number>();
  public scrolledIndexChange: Observable<number> =
    this.scrolledIndexChange$.pipe(distinctUntilChanged());

  attach(viewport: CdkVirtualScrollViewport) {
    this.viewport = viewport;
    this.updateTotalContentSize();
    this.updateRenderedRange();
  }

  detach() {
    this.scrolledIndexChange$.complete();
    delete this.viewport;
  }

  updateItemsRanges(itemsRanges: Range[]) {
    this.itemsRanges = itemsRanges;

    this.updateTotalContentSize();
    this.updateRenderedRange();
  }

  onContentScrolled() {
    this.updateRenderedRange();
  }

  onDataLengthChanged() {
    this.updateTotalContentSize();
    this.updateRenderedRange();
  }

  onContentRendered() {
    noop();
  }

  onRenderedOffsetChanged() {
    noop();
  }

  scrollToIndex(index: number, behavior: ScrollBehavior) {
    this.viewport?.scrollToOffset(this.getOffsetForIndex(index), behavior);
  }

  private getOffsetForIndex(index: number): number {
    if (!this.itemsRanges) {
      return 0;
    }

    return this.itemsRanges[index]?.start || 0;
  }
  private getIndexForOffset(offset: number): number {
    if (!this.itemsRanges) {
      return 0;
    }

    return this.itemsRanges.findIndex(
      ({ start, end }) => start <= offset && end >= offset,
    );
  }

  private updateRenderedRange() {
    if (!this.viewport) {
      return;
    }
    const offset = this.viewport.measureScrollOffset();
    const { start, end } = this.viewport.getRenderedRange();
    const viewportSize = this.viewport.getViewportSize();
    const dataLength = this.viewport.getDataLength();
    let newRange = { start, end };
    const firstVisibleIndex = this.getIndexForOffset(offset);
    const startBuffer = offset - this.getOffsetForIndex(start);

    if (this.isPreviousElementToRender(startBuffer, start)) {
      newRange = this.calculateNewRange(offset, dataLength, viewportSize);
    } else {
      const endBuffer = this.getOffsetForIndex(end) - offset - viewportSize;
      if (this.isNextElementToRender(endBuffer, end, dataLength)) {
        newRange = this.calculateNewRange(offset, dataLength, viewportSize);
      }
    }

    this.viewport.setRenderedRange(newRange);
    this.viewport.setRenderedContentOffset(
      this.getOffsetForIndex(newRange.start),
    );
    this.scrolledIndexChange$.next(firstVisibleIndex);
  }

  private isPreviousElementToRender(startBuffer: number, start: number) {
    return startBuffer < BUFFER && start !== 0;
  }

  private isNextElementToRender(
    endBuffer: number,
    end: number,
    dataLength: number,
  ) {
    return endBuffer < BUFFER && end !== dataLength;
  }

  private calculateNewRange(
    offset: number,
    dataLength: number,
    viewportSize: number,
  ) {
    const endOffset = this.getIndexForOffset(offset + viewportSize + BUFFER);

    return {
      start: Math.max(0, this.getIndexForOffset(offset - BUFFER)),
      end: endOffset < 0 ? dataLength : Math.min(dataLength, endOffset),
    };
  }

  private updateTotalContentSize() {
    const contentSize = last(this.itemsRanges)?.end || 0;
    this.viewport?.setTotalContentSize(contentSize);
  }
}
