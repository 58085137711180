export enum TrackingActionsEnum {
  SMART_DIALER_START_SESSION = 'smart_dialer_start_session',
  CALLED = 'called',
  VISIT = 'visit',
  CALL_TRANSFERRED = 'call_transferred',
  OPEN_DETAIL = 'open_detail',
  LEAVE_DETAIL = 'leave_detail',
  OPEN_DETAIL_DASHBOARD = 'open_detail_dashboard',
  FILTER_MESSAGES = 'filter_messages',
  CHOOSE = 'choose',
  SELECT_DIALER = 'select_dialer',
  SELECT_CAMPAIGN = 'select_campaign',
  START_SESSION = 'start_session',
  PAUSE_SESSION = 'pause_session',
  RESUME_SESSION = 'resume_session',
  FINISH_SESSION = 'finish_session',
  OPEN = 'open',
  SELECT_CALL = 'select_call',
  MUTE = 'mute',
  UNMUTE = 'unmute',
  HOLD = 'hold',
  UNHOLD = 'unhold',
  CLICK = 'click',
  OPEN_CRM = 'open_crm',
  DISPOSITION_SUBMITTED = 'disposition_submitted',
}
