import { APIs } from '../app/_core/enums/apis.enum';
import { APPs } from '../app/_core/enums/apps.enum';

export const environment = {
  production: true,
  name: 'e2e',
  version: require('../../package.json').version,
  devVersion: require('../../package.json').devVersion,
  // socket-io url realtime
  socketUrl: 'https://realtime-ws.cloudtalk.io',
  // sip server
  sip: 'sip.cloudtalk.io',
  // sip wss connect
  sipWss: 'wss://sip.cloudtalk.io:443',
  apis: {
    [APIs.APIv3]: 'https://api-v3.cloudtalk.io/app',
    [APIs.Realtime]: 'https://realtime.cloudtalk.io',
    [APIs.Campaigns]: 'https://campaigns.cloudtalk.io',
    [APIs.Integrations]: 'https://integrations-api.cloudtalk.io',
    [APIs.Auth]: 'https://auth.cloudtalk.io/ct-auth/api/auth',
    [APIs.Tracking]: 'https://tracking.cloudtalk.io',
    [APIs.CallsManagement]: 'https://calls-management.cloudtalk.io/api',
    [APIs.Messages]: 'https://messages.cloudtalk.io',
    [APIs.Calling]: 'https://call-service.cloudtalk.io',
    [APIs.Contacts]: 'https://contacts.cloudtalk.io',
  },
  hrefs: {
    [APPs.Analytics]: 'https://analytics.cloudtalk.io',
    [APPs.OldDashboard]: 'https://my.cloudtalk.io',
    [APPs.Dashboard]: 'https://dashboard.cloudtalk.io',
  },
  enc: {
    b64k: 'y2tfZ8BM/yBHwvYSYw5yY90AFCxa48Q3rzWEa4HtPhE=',
  },
  monitoring: {
    datadog: {
      site: 'datadoghq.eu',
      service: 'phone',
      applicationId: '0be5d944-ba20-42e0-865f-2b59ef426e09',
      clientToken: 'pub9940ef72d7637279ca089e5c969a8c65',
      sessionReplaySampleRate: 20,
      sampleRate: 20,
      traceSampleRate: 100,
    },
  },
  ssoAuthConfig: {
    userPoolWebClientId: '3bu1ecn5epki6d1j4rcg32gikl',
    oauth: {
      domain: 'cloudtalk-prod.auth.eu-central-1.amazoncognito.com',
      newDomain: 'authsso.cloudtalk.io',
      scope: ['email', 'openid'],
      redirectSignIn: '/login/sso/auth',
      responseType: 'code',
    },
  },
};
