import { NgModule } from '@angular/core';

import { TestIdDirective } from './test-id.directive';
import { IntegrationLinkDirective } from './integration-link.directive';

@NgModule({
  imports: [IntegrationLinkDirective],
  declarations: [TestIdDirective],
  exports: [TestIdDirective, IntegrationLinkDirective],
})
export class DirectivesModule {}
