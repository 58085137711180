import {
  AfterViewInit,
  Component,
  Inject,
  LOCALE_ID,
  Renderer2,
} from '@angular/core';
import { throttleTime } from 'rxjs/operators';

import { AppService } from './_core/app.service';
import { User } from './_core/models/user';
import { AnalyticsService } from './_core/services/analytics.service';
import { AuthenticationService } from './_core/services/auth/authentication.service';
import { BrowserNotificationService } from './_core/services/browser-notification.service';
import { EnvironmentService } from './_core/services/environment.service';
import { IpcService } from './_core/services/ipc.service';
import { UserService } from './_core/services/user/user.service';
import { LoggerUtil } from './_shared/utils/logger.util';
import { CookieService } from './_core/cookie.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  private logged: User = undefined;
  private unauthorized = false;

  constructor(
    private userService: UserService,
    private ipcService: IpcService,
    private environmentService: EnvironmentService,
    private renderer: Renderer2,
    private authService: AuthenticationService,
    private appService: AppService,

    private analyticsService: AnalyticsService,
    private browserNotificationService: BrowserNotificationService,
    private cookieService: CookieService,
    @Inject(LOCALE_ID) protected localeId: string,
  ) {
    this.cookieService.removeDeprecatedRefreshTokenCookie();
    this.logged = this.userService.getUser();

    this.userService.user$.subscribe(user => {
      this.logged = user;
      this.environmentService.setFormat(user?.format);
      if (this.logged?.id) {
        LoggerUtil.setUser({
          user_id: this.logged?.id,
          company_id: this.logged?.company_id,
        });
      }
    });

    this.authService.unauthorized$.pipe(throttleTime(1500)).subscribe(event => {
      this.unauthorized = event;
      if (this.unauthorized && !this.logged) {
        this.showNotificationAboutExpiredSession();
      }
    });

    this.ipcService.register();
    this.renderer.addClass(
      document.body,
      this.environmentService.env.className,
    );
  }

  ngAfterViewInit(): void {
    this.analyticsService.initAnalytics();
  }

  private toLoginScreen(): void {
    this.appService.focusApp();
  }

  private showNotificationAboutExpiredSession(): void {
    this.browserNotificationService.show({
      title: $localize`You've been logged out from CloudTalk Phone`,
      body: $localize`Your session has expired and you've been logged out. Click here to log in again.`,
    });

    const notificationSubscription =
      this.browserNotificationService.onClick$.subscribe(() => {
        this.toLoginScreen();
        notificationSubscription.unsubscribe();
      });
  }
}
