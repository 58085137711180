<button
  [ngClass]="[
    'btn-' + size,
    'btn-' + btnType,
    showSpinner ? 'btn-spinner' : ''
  ]"
  (click)="clicked.emit($event)"
  [appTestId]="testId"
  [disabled]="isDisabled">
  <ng-content></ng-content>
</button>
