import {
  HttpClient,
  HttpContext,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import {
  SnackBarTypes,
  SnackBarWrapperService,
} from '../../../_shared/components/snack-bar-wrapper/snack-bar-wrapper.service';
import { LoggerUtil } from '../../../_shared/utils/logger.util';
import { APIs } from '../../enums/apis.enum';
import { APPs } from '../../enums/apps.enum';
import { CTResponse } from './../../interfaces/ctresponse.interface';

const EXCLUDED_ERROR_CODES = [401, 403, 404, 422];

interface RequestOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  context?: HttpContext;
  observe?: 'body';
  params?:
    | HttpParams
    | {
        [param: string]:
          | string
          | number
          | boolean
          | readonly (string | number | boolean)[];
      };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

type Hrefs = {
  [key in APPs]: string;
};

@Injectable()
export class EndpointService {
  constructor(
    private http: HttpClient,
    private snackBarWrapperService: SnackBarWrapperService,
  ) {}

  get httpClient(): HttpClient {
    return this.http;
  }

  get hrefs(): Hrefs {
    return environment.hrefs;
  }

  getApiUrl(api: APIs): string {
    const apiUrl = environment.apis[api];

    if (!apiUrl) {
      throw new Error(`Api ${api} is not defined in environment`);
    }
    return apiUrl;
  }

  private handleResponse(response: CTResponse<any>, options = {}) {
    if (response?.success) {
      return response.data;
    }

    if (response instanceof Blob) {
      return window.URL.createObjectURL(response);
    }

    if (options['responseType'] === 'blob' && !response?.success) {
      return null;
    }
    return response;
  }

  private makeGetRequest(url: string, options = {}, api: APIs) {
    return this.http.get(url, options).pipe(
      map(response => this.handleResponse(response, options)),
      catchError((errorObj: HttpErrorResponse) =>
        this.catchErrorFunction({
          errorObj,
          api,
          notify: false,
        }),
      ),
    );
  }

  private makePostRequest<R, D>(
    url: string,
    data: D | string,
    api: APIs,
    options: unknown,
    notify: boolean = false,
  ): Observable<R> {
    return this.http.post<R>(url, data, options).pipe(
      map(response => {
        if (
          typeof response === 'object' &&
          'data' in response &&
          'success' in response
        ) {
          this.responseNotify(
            response['data'],
            response['success'] as boolean,
            notify,
          );
        }

        return response;
      }),
      catchError((errorObj: HttpErrorResponse) =>
        this.catchErrorFunction({ errorObj, api, notify }),
      ),
    );
  }

  private makePatchRequest<R, D>(
    url: string,
    data: D | string,
    api: APIs,
    notify: boolean,
  ): Observable<CTResponse<R>> {
    return this.http.patch<CTResponse<R>>(url, data).pipe(
      map((response: CTResponse<R>) => {
        if (response) {
          this.responseNotify(response.data, response.success, notify);
        }

        return response;
      }),
      catchError((errorObj: HttpErrorResponse) =>
        this.catchErrorFunction({ errorObj, api, notify }),
      ),
    );
  }

  private makePutRequest<R, D>(
    url: string,
    data: D,
    api: APIs,
    notify: boolean,
  ): Observable<R> {
    return this.http.put<CTResponse<R>>(url, JSON.stringify(data)).pipe(
      map((response: CTResponse<R>) => {
        if (!response) {
          return undefined;
        }
        if (response) {
          this.responseNotify(response.data, response.success, notify);
        }
        return response.data;
      }),
      catchError((errorObj: HttpErrorResponse) =>
        this.catchErrorFunction({ errorObj, api, notify }),
      ),
    );
  }

  private makeDeleteRequest<R>(
    url: string,
    api: APIs,
    notify: boolean,
  ): Observable<CTResponse<R>> {
    return this.http.delete<CTResponse<R>>(url).pipe(
      map((response: CTResponse<R>) => {
        if (response) {
          this.responseNotify(response.data, response.success, notify);
        }
        return response;
      }),
      catchError((errorObj: HttpErrorResponse) =>
        this.catchErrorFunction({ errorObj, api, notify }),
      ),
    );
  }

  get<R>(api: APIs, endpoint: string, options = {}): Observable<R> {
    return this.makeGetRequest(
      `${this.getApiUrl(api)}/${endpoint}`,
      options,
      api,
    );
  }

  /**
   * @deprecated use get instead
   * */
  _get<R>(url: string, options = {}, isJsonUrl = true): Observable<R> {
    const fullUrl =
      this.getApiUrl(APIs.APIv3) + '/' + url + (isJsonUrl ? '.json' : '');
    return this.makeGetRequest(fullUrl, options, APIs.APIv3);
  }

  /**
   * @deprecated use get instead
   * */
  _get_realtime<R>(
    url: string,
    subRoute: string = 'app/',
    options = {},
  ): Observable<R> {
    const fullUrl = `${this.getApiUrl(APIs.Realtime)}/${subRoute}${url}`;
    return this.makeGetRequest(fullUrl, options, APIs.Realtime);
  }

  /**
   * @deprecated use get instead
   * */
  _get_gateway<R>(url: string, options = {}): Observable<R> {
    const fullUrl = `${this.getApiUrl(APIs.Campaigns)}/api/${url}`;
    return this.makeGetRequest(fullUrl, options, APIs.Campaigns);
  }

  _get_local<R>(url: string, options = {}): Observable<R> {
    return this.http.get<R>(`/assets/${url}`, options);
  }

  post<R, D>(
    api: APIs,
    endpoint: string,
    data: D,
    options: RequestOptions = {},
  ): Observable<R> {
    return this.makePostRequest<R, D>(
      `${this.getApiUrl(api)}/${endpoint}`,
      data,
      api,
      options,
    );
  }

  /**
   * @deprecated use post instead
   * */
  _post<R, D>(
    url: string,
    data: D,
    notify: boolean = true,
  ): Observable<CTResponse<R>> {
    const fullUrl = this.getApiUrl(APIs.APIv3) + '/' + url + '.json';
    return this.makePostRequest<CTResponse<R>, D>(
      fullUrl,
      JSON.stringify(data),
      APIs.APIv3,
      undefined,
      notify,
    );
  }

  /**
   * @deprecated use post instead
   * */
  _post_realtime<R, D>(
    url: string,
    data: D,
    notify: boolean = false,
    subRoute: string = 'app/',
  ): Observable<CTResponse<R>> {
    const fullUrl = `${this.getApiUrl(APIs.Realtime)}/${subRoute}${url}`;
    return this.makePostRequest<CTResponse<R>, D>(
      fullUrl,
      data,
      APIs.Realtime,
      undefined,
      notify,
    );
  }

  /**
   * @deprecated use post instead
   * */
  _post_gateway<R, D>(
    url: string,
    data: D,
    notify: boolean = false,
  ): Observable<CTResponse<R>> {
    const fullUrl = `${this.getApiUrl(APIs.Campaigns)}/api/${url}`;
    return this.makePostRequest<CTResponse<R>, D>(
      fullUrl,
      data,
      APIs.Campaigns,
      undefined,
      notify,
    );
  }

  patch<R, D>(
    api: APIs,
    endpoint: string,
    data: D,
    notify: boolean = true,
  ): Observable<CTResponse<R>> {
    return this.makePatchRequest<R, D>(
      `${this.getApiUrl(api)}/${endpoint}`,
      data,
      api,
      notify,
    );
  }

  /**
   * @deprecated use patch instead
   * */
  _patch<R, D>(
    url: string,
    data: D,
    notify: boolean = true,
  ): Observable<CTResponse<R>> {
    const fullUrl = this.getApiUrl(APIs.APIv3) + '/' + url + '.json';
    return this.makePatchRequest<R, D>(
      fullUrl,
      JSON.stringify(data),
      APIs.APIv3,
      notify,
    );
  }

  /**
   * @deprecated use patch instead
   * */
  _patch_realtime<R, D>(
    url: string,
    data: D,
    notify: boolean = true,
  ): Observable<CTResponse<R>> {
    const fullUrl = `${this.getApiUrl(APIs.Realtime)}/app/${url}`;
    return this.makePatchRequest<R, D>(fullUrl, data, APIs.Realtime, notify);
  }

  /**
   * @deprecated use patch instead
   * */
  _patch_gateway<R, D>(
    url: string,
    data: D,
    notify: boolean = true,
  ): Observable<CTResponse<R>> {
    const fullUrl = `${this.getApiUrl(APIs.Campaigns)}/api/${url}`;
    return this.makePatchRequest<R, D>(fullUrl, data, APIs.Campaigns, notify);
  }

  put<R, D>(
    api: APIs,
    endpoint: string,
    data: D,
    notify: boolean = true,
  ): Observable<R> {
    return this.makePutRequest<R, D>(
      `${this.getApiUrl(api)}/${endpoint}`,
      data,
      api,
      notify,
    );
  }

  /**
   * @deprecated use put instead
   * */
  _put_realtime<R, D>(
    url: string,
    data: D,
    notify: boolean = true,
  ): Observable<R> {
    const fullUrl = `${this.getApiUrl(APIs.Realtime)}/app/${url}`;
    return this.makePutRequest<R, D>(fullUrl, data, APIs.Realtime, notify);
  }

  /**
   * @deprecated use put instead
   * */
  _put_gateway<R, D>(
    url: string,
    data: D,
    notify: boolean = true,
  ): Observable<R> {
    const fullUrl = `${this.getApiUrl(APIs.Campaigns)}/api/${url}`;
    return this.makePutRequest<R, D>(fullUrl, data, APIs.Campaigns, notify);
  }

  delete<R>(
    api: APIs,
    endpoint: string,
    notify: boolean = true,
  ): Observable<CTResponse<R>> {
    return this.makeDeleteRequest<R>(
      `${this.getApiUrl(api)}/${endpoint}`,
      api,
      notify,
    );
  }

  /**
   * @deprecated use delete instead
   * */
  _delete<R>(url: string, notify: boolean = true): Observable<CTResponse<R>> {
    const fullUrl = this.getApiUrl(APIs.APIv3) + '/' + url + '.json';
    return this.makeDeleteRequest<R>(fullUrl, APIs.APIv3, notify);
  }

  /**
   * @deprecated use delete instead
   * */
  _delete_realtime<R>(
    url: string,
    notify: boolean = true,
  ): Observable<CTResponse<R>> {
    const fullUrl = `${this.getApiUrl(APIs.Realtime)}/app/${url}`;
    return this.makeDeleteRequest<R>(fullUrl, APIs.Realtime, notify);
  }

  /**
   * @deprecated use delete instead
   * */
  _delete_gateway<R>(
    url: string,
    notify: boolean = true,
  ): Observable<CTResponse<R>> {
    const fullUrl = `${this.getApiUrl(APIs.Campaigns)}/api/${url}`;
    return this.makeDeleteRequest<R>(fullUrl, APIs.Campaigns, notify);
  }

  responseNotify(message: unknown, success: boolean, notify: boolean): void {
    if (message && notify && typeof message === 'string') {
      this.snackBarWrapperService.openSnackBar({
        message,
        snackType: success ? SnackBarTypes.SUCCESS : SnackBarTypes.ERROR,
      });
    }
  }

  catchErrorFunction({
    errorObj,
    api,
    notify,
  }: {
    errorObj: HttpErrorResponse;
    api: APIs;
    notify: boolean;
  }): Observable<never> {
    if (!EXCLUDED_ERROR_CODES.includes(errorObj.status)) {
      LoggerUtil.error(`[${api}]: ${errorObj.message}`, {
        extraContent: { errorObj },
        tags: { call_uuid: undefined, endpoint: errorObj.url },
      });
    }

    if (notify && errorObj && errorObj.error) {
      this.snackBarWrapperService.openSnackBar({
        message: errorObj.error.message || errorObj.error.error?.message,
        snackType: SnackBarTypes.ERROR,
      });
    }
    return throwError(errorObj);
  }
}
